export const MESSAGES = {
    'ERROR_INITIATE_CHAT': 'Error while initializing chat. Please try again later.',
    'END_CHAT_CONFIRMATION': 'Are you sure you want to end the chat?',
    'AGENTS_NOT_AVAILABLE': 'Agents are not available at this time. Please try after some time.',
    'OOH_MESSAGE': 'You have reached us outside our operating hours. Please try again during our operating hours.',
    'WAITING_FOR_AGENT': 'Please wait for an agent to join the chat.'
}

export const PRODUCTION = 'production'

export const REFRESH_INTERVAL = 1000 * 60 *2 ; // 2 minutes

export const UNAVAILABLE_REASON = {
    "OOH" :"OPERATING_HOURS",
    "NO_AGENTS": "AGENT_UNAVAILABLE"
}