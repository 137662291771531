
<script setup>
import { defineProps, ref, defineEmits, computed } from 'vue'
import {
  PaperClipIcon,
} from '@heroicons/vue/24/solid';

const props = defineProps({
  isTyping: Boolean,
  systemMessage: String,
  processUpload: Function,
  customerTyping: Function,
  sendingMessage: Boolean,
  messageToSend: String,
  sendMessage: Function,
})
defineEmits(['update:messageToSend'])

const fileUpload = ref(null);

</script>

<template>
  <div class="!zf-mt-2 !zf-w-full !zf-h-[10%] !zf-px-4">
    <p v-if="isTyping">Agent is typing...</p>
    <div class="!zf-flex !zf-gap-2 !zf-items-center !zf-justify-between">
      <input type="file" @change="processUpload($event)" class="zf-hidden" ref="fileUpload" />
      <button @click="fileUpload.click()" class="zf-bg-transparent">
        <PaperClipIcon class="zf-h-5 zf-w-5" />
      </button>
      <input id="chatInputBox" type="text" placeholder="Type your message here..."
        class="!zf-w-full !zf-rounded-lg !zf-bg-gray-100 !zf-px-4 !zf-py-2" :value="messageToSend"
        @input="$emit('update:messageToSend', $event.target.value)" @keyup="customerTyping" :disabled="sendingMessage"
        :class="{ 'zf-bg-gray-100 animate-pulse': sendingMessage }" />
      <button
        class="!zf-bg-ziply-blue !zf-rounded-lg !zf-px-4 !zf-py-2 !zf-text-gray-100 hover:zf-bg-ziply-blue-dark !zf-text-sm !zf-fill-white"
        :class="{
          '!zf-bg-gray-200 !zf-fill-ziply-blue': messageToSend.length === 0,
        }" :disabled="sendingMessage" @click="sendMessage()">
        SEND
      </button>
    </div>
  </div>
</template>