
import { createApp } from 'vue'
import './style.css'

import CustomerChat from './CustomerChat.vue'
import OmniChat from './OmniChat.vue'
import InternalChat from './InternalChat.vue'
import OmniChatInternal from './OmniChatInternal.vue'
import EnterpriseChat from './EnterpriseChat.vue'
import OmniChatEnterprise from './OmniChatEnterprise.vue'
    
const customerChat = createApp(CustomerChat)
customerChat.component('omni-chat', OmniChat);
customerChat.mount('#MS365ChatWidget')

const internalChat = createApp(InternalChat)
internalChat.component('omni-chat-internal', OmniChatInternal);
internalChat.mount('#MS365ChatWidgetInternal')

const enterpriseChat = createApp(EnterpriseChat)
enterpriseChat.component('omni-chat-enterprise', OmniChatEnterprise);
enterpriseChat.mount('#MS365ChatWidgetEnterprise')
