<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
    isInternal: Boolean,
    channelOptions: Object,
    preChatSurveyResponses: Object,
    preChatQuestions: Object,
    isLoading: Boolean,
    startChat: Function
})

const formErrors = ref({});
const labelToIdMap = ref({});

let formFields = ref([]);
try {
    //console.log(props.preChatQuestions);
    props.preChatQuestions?.body?.forEach(element => {
        if (element.type === 'Input.Text' || element.type === 'Input.ChoiceSet') {
            try {
                const meta = JSON.parse(element.id)
                element = {
                    ...meta,
                    Name: `${meta.QuestionText?.trim()}`,
                    ...element
                }
                formFields.value.push(element)
                labelToIdMap.value[meta.QuestionText] = element.id
            }
            catch (err) {
                console.log(err)
            }
        }
    });
}
catch (err) {
    console.log(err)
}
</script>

<template>
    <form method="post" class="internal-chat-form" @submit.prevent="startChat(preChatSurveyResponses, labelToIdMap)">
        <template v-for="item in formFields" :key="item.id">
            <div class="form-item">
                <label :for="item.Id">{{ item.Name }} <span class="zf-text-red-700 zf-ml-[0.5rem]"
                        v-if="item.IsRequired">*</span></label>
                <input type="text" :id="item.Id" v-model.trim="preChatSurveyResponses[item.id]"
                    :placeholder="item.QuestionText" :required="item.IsRequired" :class="{
                        '!zf-border !zf-border-red-700 zf-mb-0': formErrors[item.Id],
                    }" class="!zf-w-full zf-rounded !zf-mb-4 !zf-px-4 !zf-bg-gray-100 !zf-py-2 !zf-h-12"
                    :disabled="isLoading" v-if="item.type === 'Input.Text' && !item.isMultiline" />

                <select :id="item.Id" v-model.trim="preChatSurveyResponses[item.id]" :placeholder="item.QuestionText"
                    :required="item.IsRequired" :class="{
                        '!zf-border !zf-border-red-700 zf-mb-0': formErrors[item.Id],
                    }" class="!zf-w-full zf-rounded !zf-mb-4 !zf-px-4 !zf-bg-gray-100 !zf-py-2 !zf-h-12"
                    v-if="item.type === 'Input.ChoiceSet'" :disabled="isLoading">
                    <option v-for="option in item.choices" :value="option.value">{{ option.title }}</option>
                </select>
                <textarea :id="item.id" v-model.trim="preChatSurveyResponses[item.id]" :placeholder="item.QuestionText"
                    :disabled="isLoading" :required="item.IsRequired" :class="{
                        '!zf-border !zf-border-red-700 zf-mb-0': formErrors[item.Id],
                    }" class="!zf-w-full zf-rounded !zf-mb-4 !zf-px-4 !zf-bg-gray-100 !zf-py-2 !zf-h-12"
                    v-if="item.type === 'Input.Text' && item.isMultiline" />
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm p-0" v-if="formErrors[item.Id]">
                    {{ formErrors[item.Id] }}
                </p>
            </div>
        </template>
        <input type="submit" value="START CHAT"
            class="!zf-px-8 !zf-py-2 zf-rounded-full !zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark zf-text-gray-100 zf-cursor-pointer zf-font-bold"
            v-if="!isLoading && formFields.length > 0" />
    </form>
</template>